/**
 * angular-bootstrap-calendar - A pure AngularJS bootstrap themed responsive calendar that can display events and has views for year, month, week and day
 * @version v0.23.0
 * @link https://github.com/mattlewis92/angular-bootstrap-calendar
 * @license MIT
 */

#cal-week-box {
    display: none;
}

[class*='cal-cell'] {
    float: left;
    margin-left: 0;
    min-height: 1px;
}
.cal-row-fluid {
    width: 100%;
    *zoom: 1;
}
.cal-row-fluid:before,
.cal-row-fluid:after {
    display: table;
    content: '';
    line-height: 0;
}
.cal-row-fluid:after {
    clear: both;
}
.cal-row-fluid [class*='cal-cell'] {
    display: block;
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    float: left;
    margin-left: 0%;
    *margin-left: -0.05213764%;
}
.cal-row-fluid [class*='cal-cell']:first-child {
    margin-left: 0;
}
.cal-row-fluid .controls-row [class*='cal-cell'] + [class*='cal-cell'] {
    margin-left: 0%;
}
.cal-row-fluid .cal-cell7 {
    width: 100%;
    *width: 99.94669509594883%;
}
.cal-row-fluid .cal-cell6 {
    width: 85.71428571428571%;
    *width: 85.66098081023453%;
}
.cal-row-fluid .cal-cell5 {
    width: 71.42857142857142%;
    *width: 71.37526652452024%;
}
.cal-row-fluid .cal-cell4 {
    width: 57.14285714285714%;
    *width: 57.089552238805965%;
}
.cal-row-fluid .cal-cell3 {
    width: 42.857142857142854%;
    *width: 42.80383795309168%;
}
.cal-row-fluid .cal-cell2 {
    width: 28.57142857142857%;
    *width: 28.518123667377395%;
}
.cal-row-fluid .cal-cell1 {
    width: 14.285714285714285%;
    *width: 14.232409381663112%;
}
.cal-week-box .cal-offset7,
.cal-row-fluid .cal-offset7,
.cal-row-fluid .cal-offset7:first-child {
    margin-left: 100%;
    *margin-left: 99.89339019189765%;
}
.cal-week-box .cal-offset6,
.cal-row-fluid .cal-offset6,
.cal-row-fluid .cal-offset6:first-child {
    margin-left: 85.71428571428571%;
    *margin-left: 85.60767590618336%;
}
.cal-week-box .cal-offset5,
.cal-row-fluid .cal-offset5,
.cal-row-fluid .cal-offset5:first-child {
    margin-left: 71.42857142857142%;
    *margin-left: 71.32196162046907%;
}
.cal-week-box .cal-offset4,
.cal-row-fluid .cal-offset4,
.cal-row-fluid .cal-offset4:first-child {
    margin-left: 57.14285714285714%;
    *margin-left: 57.03624733475479%;
}
.cal-week-box .cal-offset3,
.cal-row-fluid .cal-offset3,
.cal-row-fluid .cal-offset3:first-child {
    margin-left: 42.857142857142854%;
    *margin-left: 42.750533049040506%;
}
.cal-week-box .cal-offset2,
.cal-row-fluid .cal-offset2,
.cal-row-fluid .cal-offset2:first-child {
    margin-left: 28.57142857142857%;
    *margin-left: 28.46481876332622%;
}
.cal-week-box .cal-offset1,
.cal-row-fluid .cal-offset1,
.cal-row-fluid .cal-offset1:first-child {
    margin-left: 14.285714285714285%;
    *margin-left: 14.17910447761194%;
}
.cal-row-fluid .cal-cell1 {
    width: 14.285714285714285%;
    *width: 14.233576642335766%;
}
[class*='cal-cell'].hide,
.cal-row-fluid [class*='cal-cell'].hide {
    display: none;
}
[class*='cal-cell'].pull-right,
.cal-row-fluid [class*='cal-cell'].pull-right {
    float: right;
}
.cal-row-head [class*='cal-cell']:first-child,
.cal-row-head [class*='cal-cell'] {
    min-height: auto;
    overflow: hidden;
    text-overflow: ellipsis;
}
.cal-events-num {
    margin-top: 20px;
}
.cal-month-day {
    position: relative;
    display: block;
    width: 100%;
}
.cal-month-day .cal-events-num {
    margin-left: 10px;
    margin-top: 18px;
}
#cal-week-box {
    position: absolute;
    width: 70px;
    left: -71px;
    top: -1px;
    padding: 8px 5px;
    cursor: pointer;
}
.cal-day-tick {
    position: absolute;
    right: 50%;
    bottom: -21px;
    padding: 0px 5px;
    cursor: pointer;
    z-index: 5;
    text-align: center;
    width: 26px;
    margin-right: -17px;
}
.cal-year-box #cal-day-tick {
    margin-right: -7px;
}
.cal-slide-box {
    position: relative;
}
.cal-slide-tick {
    position: absolute;
    width: 16px;
    margin-left: -7px;
    height: 9px;
    top: -1px;
    z-index: 1;
}
.cal-slide-tick.tick-month1 {
    left: 12.5%;
}
.cal-slide-tick.tick-month2 {
    left: 37.5%;
}
.cal-slide-tick.tick-month3 {
    left: 62.5%;
}
.cal-slide-tick.tick-month4 {
    left: 87.5%;
}
.cal-slide-tick.tick-day1 {
    left: 7.14285714285715%;
}
.cal-slide-tick.tick-day2 {
    left: 21.42857142857143%;
}
.cal-slide-tick.tick-day3 {
    left: 35.71428571428572%;
}
.cal-slide-tick.tick-day4 {
    left: 50%;
}
.cal-slide-tick.tick-day5 {
    left: 64.2857142857143%;
}
.cal-slide-tick.tick-day6 {
    left: 78.57142857142859%;
}
.cal-slide-tick.tick-day7 {
    left: 92.85714285714285%;
}
.events-list {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 50;
}
.cal-slide-content ul.unstyled {
    margin-bottom: 0;
}
.cal-slide-content ul li.dragging-active .event-item {
    color: black;
}
.cal-day-selected {
    background-color: #ededed;
}
.cal-week-box {
    position: relative;
}
.cal-week-box [data-event-class] {
    white-space: nowrap;
    height: 30px;
    line-height: 30px;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-top: 0px !important;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    font-size: 12px;
    padding: 0 3px !important;
}
.cal-week-box .cal-day-panel {
    border: 0px !important;
}
.cal-week-box:not(.cal-day-box) .cal-row-fluid {
    margin-bottom: 2px;
}
.cal-week-box:not(.cal-day-box) .cal-row-fluid:hover,
.cal-week-box:not(.cal-day-box) [class*='cal-cell']:hover {
    background-color: inherit !important;
}
.cal-week-box:not(.cal-day-box) [data-event-class] {
    margin-left: 2px;
    margin-right: 2px;
}
.cal-week-box:not(.cal-day-box) .border-left-rounded {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}
.cal-week-box:not(.cal-day-box) .border-right-rounded {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}
.cal-week-box.cal-day-box .cal-row-head {
    padding-left: 60px;
}
.cal-week-box.cal-day-box .cal-day-panel {
    overflow-x: hidden;
}
.cal-day-box {
    text-wrap: none;
    overflow-x: auto !important;
    overflow-y: hidden;
}
.cal-day-box .cal-day-hour-part {
    height: 30px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border-bottom: thin dashed #e1e1e1;
}
.cal-day-box .cal-day-hour-part .cal-day-hour-part-time {
    width: 60px;
    text-align: center;
    float: left;
}
.cal-day-box .cal-day-hour-part .cal-day-hour-part-spacer {
    height: 30px;
    display: inline-block;
}
.cal-day-box .cal-day-hour-part:hover {
    background-color: #ededed;
}
.cal-day-box .cal-day-hour-part-selected {
    background-color: #e1e1e1;
}
.cal-day-box .cal-day-hour .day-highlight {
    height: 30px;
}
.cal-day-box .cal-hours {
    font-weight: bold;
    font-size: 12px;
}
.cal-day-box .cal-day-hour:nth-child(odd) {
    background-color: #fafafa;
}
.cal-day-box .cal-day-panel {
    position: relative;
    padding-left: 60px;
    border: solid 1px #e1e1e1;
}
.cal-day-box .cal-day-panel-hour {
    position: absolute;
    width: 100%;
    margin-left: -60px;
}
.cal-day-box .day-event {
    position: absolute;
    width: 150px;
    overflow: hidden;
    padding: 2px 3px !important;
}
.cal-day-box .day-event a {
    font-size: 12px;
    text-overflow: ellipsis;
}
.cal-day-box .day-highlight {
    padding-top: 2px;
    padding-left: 8px;
    padding-right: 8px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border: 1px solid #c3c3c3;
    margin: 1px 1px;
    overflow: hidden;
    text-overflow: ellipsis;
}
.event {
    display: block;
    background-color: #c3c3c3;
    width: 12px;
    height: 12px;
    margin-right: 2px;
    margin-bottom: 2px;
    -webkit-box-shadow: inset 0px 0px 5px 0px rgba(0, 0, 0, 0.4);
    box-shadow: inset 0px 0px 5px 0px rgba(0, 0, 0, 0.4);
    border-radius: 8px;
    border: 1px solid #ffffff;
}
.event-block {
    display: block;
    background-color: #c3c3c3;
    width: 20px;
    height: 100%;
}
.cal-event-list .event.pull-left {
    margin-top: 3px;
}
.day-highlight:hover,
.day-highlight {
    background-color: #dddddd;
}
.cal-row-head [class*='cal-cell']:first-child,
.cal-row-head [class*='cal-cell'] {
    font-weight: bolder;
    text-align: center;
    border: 0px solid;
    padding: 5px 0;
}
.cal-row-head [class*='cal-cell'] small {
    font-weight: normal;
}
.cal-year-box .row:hover,
.cal-row-fluid:hover {
    background-color: #fafafa;
}
.cal-month-day {
    height: 50px;
}
[class*='cal-cell']:hover,
.cell-focus,
[class*='cal-cell'] .drop-active,
.cal-cell.drop-active,
.cal-week-box .cal-cell1.drop-active,
.cal-day-hour-part.drop-active {
    background-color: #ededed;
}
.cal-year-box [class*='span'],
.cal-month-box [class*='cal-cell'] {
    min-height: 50px;
    border-right: 1px solid #e1e1e1;
    position: relative;
}
.cal-year-box [class*='span'] {
    min-height: 60px;
}
.cal-year-box .row [class*='col-']:last-child,
.cal-month-box .cal-row [class*='cal-cell']:last-child {
    border-right: 0px;
}
.cal-year-box .row,
.cal-month-box .cal-row-fluid {
    border-bottom: 1px solid #e1e1e1;
    margin-left: 0px;
    margin-right: 0px;
}
.cal-year-box .row:last-child,
.cal-month-box .cal-row-fluid:last-child {
    border-bottom: 0px;
}
.cal-month-box,
.cal-year-box,
.cal-week-box {
    border-top: 1px solid #e1e1e1;
    border-bottom: 1px solid #e1e1e1;
    border-right: 1px solid #e1e1e1;
    border-left: 1px solid #e1e1e1;
    border-radius: 2px;
}
.cal-month-box {
    border-right: 0px;
    border-bottom: 0px;
}
span[data-cal-date] {
    font-size: 1.2em;
    font-weight: normal;
    opacity: 0.5;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.1s ease-in-out;
    -moz-transition: all 0.1s ease-in-out;
    -ms-transition: all 0.1s ease-in-out;
    -o-transition: all 0.1s ease-in-out;
    margin-top: 15px;
    margin-right: 15px;
}
span[data-cal-date]:hover {
    opacity: 1;
}
.cal-day-outmonth span[data-cal-date] {
    opacity: 0.1;
    cursor: default;
}
.cal-day-today {
    background-color: #e8fde7;
}
.cal-day-today span[data-cal-date] {
    color: darkgreen;
}
.cal-month-box .cal-day-today span[data-cal-date] {
    font-size: 1.8em;
}
.cal-day-holiday span[data-cal-date] {
    color: #800080;
}
.cal-day-weekend span[data-cal-date] {
    color: darkred;
    font-weight: 700;
}
#cal-week-box {
    border: 1px solid #e1e1e1;
    border-right: 0px;
    border-radius: 5px 0 0 5px;
    background-color: #fafafa;
    text-align: right;
}
.cal-week-box .cal-row-head {
    border-bottom: 1px solid #e1e1e1;
}
.cal-day-tick {
    border: 1px solid #e1e1e1;
    border-top: 0px solid;
    border-radius: 0 0 5px 5px;
    background-color: #ededed;
    text-align: center;
}
.cal-day-tick .fa {
    display: none;
}
.cal-slide-box {
    border-top: 0px solid #8c8c8c;
}
.cal-slide-content {
    padding: 20px;
    color: #ffffff;
    background-color: #555555;
    -webkit-box-shadow: inset 0px 0px 15px 0px rgba(0, 0, 0, 0.5);
    box-shadow: inset 0px 0px 15px 0px rgba(0, 0, 0, 0.5);
}
.cal-slide-content a.event-item {
    color: #ffffff;
    font-weight: normal;
}
a.event-item-edit,
a.event-item-delete,
a.event-item-action {
    padding-left: 5px;
}
.cal-year-box .cal-slide-content a.event-item,
.cal-year-box a.event-item-edit,
.cal-year-box a.event-item-delete,
.cal-year-box a.event-item-action {
    position: relative;
    top: -3px;
}
.events-list {
    max-height: 47px;
    padding-left: 5px;
}
.cal-column {
    border-left: 1px solid #e1e1e1;
}
a.cal-event-week {
    text-decoration: none;
    color: #151515;
}
.badge-important {
    background-color: #b94a48;
}
.pointer {
    cursor: pointer;
}
.cal-year-box:last-child {
    border-bottom: 0px;
}
.cal-context {
    width: 100%;
}
@media (max-width: 991px) {
    .cal-year-box [class*='span']:nth-child(2) {
        border-right: 0px;
    }
    .cal-year-box [class*='span']:nth-child(1),
    .cal-year-box [class*='span']:nth-child(2) {
        border-bottom: 1px solid #e1e1e1;
    }
}
