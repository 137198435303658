
.App {
  text-align: center;
  background: red;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.pg-viewer-wrapper .document-container {
  width: auto !important;
}

.react-paginate ul {
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
}

.react-paginate li {
  display: inline-block;
}

.react-paginate .selected {
  background: blue;
}

input, select, textarea {
  font-size: 16px !important;
}

.mygizmo-logo {
  background-image: url("./mygizmo_logo.png");
  height: 34px;
  width: 100px;
  background-size: contain;
  background-repeat: no-repeat;
}

.mygizmo-white-logo {
  background-image: url("./mygizmo_white_logo.png");
  height: 34px;
  width: 100px;
  background-size: contain;
  background-repeat: no-repeat;
}

.compensation-icon {
  background-image: url("./compensation-icon.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: inline-block;
  height: 14px;
  width: 14px;
  max-height: 14px;
  min-width: 14px;
}

.active-icon {
  filter: invert(52%) sepia(94%) saturate(592%) hue-rotate(357deg) brightness(102%) contrast(110%);
}

@media only screen and (min-width: 600px) {
  body {
    font-size: 16px !important;
  }
}

.OnTop {
  z-index: 999999;
}

#root {
  height: 100vh;
}

.sliding-pane-menu {
  max-width: 400px;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.slide-pane__content {
  padding-bottom: 0 !important;
}

.ReactFlagsSelect-module_selectBtn__19wW7 {
  border: none !important;
  margin-top: 5px;
}

.integration-column {
  width: 60px;
}

.xsmall-column {
  width: 58px;
}

.xsmall-column2 {
  width: 80px;
}

.sigCanvas {
  background: white;
  border: 4px solid lightgrey;
}

.small-column {
  width: 100px;
}

.small2-column {
  width: 120px;
}

.medium-column {
  width: 150px;
}

.medium2-column {
  width: 180px;
}

.react-bootstrap-table {
  max-width: 100%;
  overflow: auto;
}

.loader-icon {
  background: url(./mygizmo_loader.gif);
  height: 20px;
  width: 20px;
  background-size: cover;
}

.react-bootstrap-table > table {
  background: white;
  table-layout: auto !important;
}

.react-bootstrap-table > .fixed {
  table-layout: fixed !important;
}

/* .react-bootstrap-table > table > tbody > tr > td {
  min-width: 100px;
} */

.react-bootstrap-table > .shrink {
  min-width: 700px !important;
}

.react-bootstrap-table > .noMinWidth {
  min-width: initial !important;
}

.react-bootstrap-table > table > thead {
  font-weight: bold;
  text-transform: uppercase;
  color: #242c40;
}

.react-bootstrap-table > .table > thead > tr > th {
  white-space: nowrap;
  /* border: 1px solid #ddd !important; */
  font-size: 12px;
  color: #3e485a;
}
.salarySummaryTable > thead > tr > th {
  font-size: initial !important;
  text-transform: none !important;
}

.salarySummaryTable {
  background: none !important;
}

.dropdown {
  margin: 4px;
}

.react-bootstrap-table > .table > tbody > tr > td {
  overflow: auto;
  max-width: 400px;
  word-wrap: break-word;
}
.expand-cell-header {
  width: 36px !important;
}
.editable:hover {
  background: #ffd89c;
  cursor: text;
}

.react-bootstrap-table-editing-cell {
  box-shadow: 0 0 0 2px inset #ff9900;
}

.react-bootstrap-table-editing-cell > input, .react-bootstrap-table-editing-cell > select {
  background: none !important;
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
}

.nav-pills>li.active>a, .nav-pills>li.active>a:focus, .nav-pills>li.active>a:hover {
  color: black;
  background: white;
  border-bottom: 4px solid #FF9900;
}

.nav-pills>li>a {
  border: 1px solid #ddd;
  border-bottom: 4px solid #DDD;
  color: #41444c;
  background: white;
  padding: 0.5em 1em;
}

.nav-pills {
  margin: -4px;
}

.nav-pills>li {
  margin: 4px;
}

#previewSalaryContainer {
  width: 790px;
  padding: 12px;
  background: white;
}

@media (max-width: 900px) {  
  .previewSalaryModal .modal-body {
    overflow: auto;
  } 
}

.page-break-after {
  page-break-after: always;
  height: 40px;
}

.previewSalaryModal {
  width: 840px !important;
  max-width: calc(100% - 20px);
}

.nav-pills>li+li {
  margin-left: 0.5em;
}

.btn, .form-control {
  border-radius: 8px;
}

.mg-paginate-container {
  display: flex;
  padding: 0;
  margin: 0;
  justify-content: flex-end;
}

.mg-paginate-container > li {
  display: block;
  padding: 3px;
}

.mg-paginate-container > li.selected > a {
  background: #232b41;
  color: #FF9900;
}


.mg-paginate-container > li > a {
  height: 36px;
  width: 36px;
  border-radius: 50%;
  border: 1px solid #232b41;
  color: #232b41;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.truncate-text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.active-navbar-tab {
  color: #FF9900 !important;
  border-color: #FF9900 !important;
}

.active-navbar-tab > label {
  color: #FF9900 !important;
}

.navbar-tooltip {
  padding-top: 0;
  padding-bottom: 0;
}

.mg-tooltip.show {
  opacity: 1 !important;
}

.max900 {
  max-width: 900px;
}

@media (min-width: 1200px) {
  .modal-lg {
    width: 1200px;
  }
}

.outlined {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 16px;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.outlined:disabled {
  cursor: not-allowed;
}

.outlined-danger {
  color: #dc3545;
  background-color: transparent;
  background-image: none;
  border-color: #dc3545;
}

.outlined-danger:not(:disabled):not(.disabled):hover{
  color: white;
  background-color: #dc3545;
  background-image: none;
  border-color: #dc3545;
}

small {
  font-weight: normal;
}

.keyboard-key {
  border: 1px solid #aaa;
  border-radius: 0.2em;
  box-shadow: 0.1em 0.1em 0.2em rgb(0 0 0 / 10%);
  background-color: #f9f9f9;
  background-image: linear-gradient(to bottom,#eee,#f9f9f9,#eee);
  color: #000;
  padding: 0.1em 0.3em;
  font-family: inherit;
  font-size: 0.85em;
}

.CalendarDay__highlighted_calendar {
  background: #029cce !important;
  color: white !important;
}

.CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover {
  background: #ffa115 !important; 
  border: 1px solid #ff9900 !important; 
}

.CalendarDay__selected_span {
  background: #ffc66f !important; 
  border: 1px solid #ff9900 !important; 
}

.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: #ffe8c6 !important;
  border: 1px solid #fcdaa8 !important; 
  color: inherit !important;
}

.DateInput_input__focused {
  border-color: #ff9900 !important;
}

.btn.mg-primary {
  background: #FF9900;
  border-color: #FF9900;
  color: white;
}

.btn.mg-primary:hover {
  background: #f29304;
  border-color: #f29304;
  color: white;
}

.btn.mg-primary:active {
  background: #ce7c02;
  border-color: #ce7c02;
  color: white;
}

.expandSearchOnFocus {
  transition: ease width 150ms;
}

.expandSearchOnFocus:placeholder-shown {
  width: 60px;
}

.expandSearchOnFocus:focus, .expandSearchOnFocus:not(:placeholder-shown) {
  width: 150px;
}