.generic-modal > .modal-dialog {
  margin-top: 200px;
}

.navbar-brand {
    background-image: url("../images/vectorlogo.svg");
    width: 160px;
    height: 60px;
    background-size: 100% auto;
    background-repeat: no-repeat;
    margin-left: 10px;
    margin-top: 4px;
    -webkit-font-smoothing: antialiased;
  }
  
  .navbar-date {
    color: #eb965d;
    height: 100px;
    width: 100px;
  }
  
  .page-title {
    border: 1px solid black;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom: none;
    margin-bottom: 0px;
    margin-top: 40px;
    width: 200px;
    height: 75px;
    border-left: 2px solid;
    border-right: 2px solid;
    border-top: 2px solid;
    border-color: darkorange;
    background-color: #373a3c;
    padding: 17px;
    color: orange;
    font-family: verdana;
    font-size: 30px;
  }
  
  .navbar-static-top {
    margin-left: 0px;
    margin-right: 0px;
  }
  
  .navbar-static-top .row {
    margin-left: 0px;
    margin-right: 0px;
  }
  
  .data-container-table {
    margin-bottom: 0px;
  }
  
  .table-panel {
    border: 2px solid;
    border-color: darkorange;
    margin-top: 17px;
    margin-bottom: 0px;
  }
  
  .bordered-table {
    border: 1px solid #ddd;
  }
  
  .panel-info > .panel-heading {
    background-color: #373a3c;
    color: white;
  }
  
  .panel-info > .panel-footer {
    height: 40px;
  }
  
  .panel-info {
    border: 2px solid darkorange;
  }
  
  #btn-add {
    float: right;
  }
  
  .button-container {
    padding: 20px;
    width: 100%;
    margin-top: 10px;
  }
  
  @media (min-width: 639px) {
    .button-container {
      width: 75%;
    }
  }
  
  @media (min-width: 992px) {
    .button-container {
      margin-top: 0px;
      width: 100%;
    }
  
    .center-a-div {
      text-align: center;
    }
  }
  
  @media (min-width: 1121px) {
    .button-container {
      height: 75px;
    }
  }
  
  .mini-table {
    padding: 0px;
    display: box;
  }
  
  .cssSlideUp {
    transition: 0.2s linear all;
    height: 80px;
    overflow: hidden;
  }
  
  .cssSlideUp.ng-hide {
    height: 0;
  }
  
  .btn-padding1 {
    margin: 0px;
    float: none;
  }
  
  .btn-padding2 {
    margin: 0px;
    float: none;
  }
  
  @media (max-width: 1120px) {
    .btn-padding1 {
      margin-bottom: 7px;
      width: 100%;
      float: none;
    }
  
    .btn-padding2 {
      width: 100%;
      float: none;
    }
  }
  
  @media (min-width: 1120px) {
    .btn-padding1 {
      float: left;
    }
  
    .btn-padding2 {
      float: right;
    }
  }
  
  .table-responsive {
    margin-bottom: 20px;
  }
  
  .table {
    margin-bottom: 0px;
  }
  
  @media (max-width: 1340px) {
    .btn-padding1-small {
      margin-bottom: 7px;
      width: 100%;
      float: none;
    }
  
    .btn-padding2-small {
      width: 100%;
      float: left;
    }
  }
  
  @media (min-width: 1340px) {
    .btn-padding1-small {
      float: right;
      margin-left: 10px;
    }
  
    .btn-padding2-small {
      float: right;
    }
  }
  
  .button-container-search {
    height: 75px;
    padding: 20px;
    margin-left: 15px;
    margin-right: 15px;
  }
  
  @media (max-width: 639px) {
    #btn-add {
      float: none;
      margin-top: 5px;
      width: 100%;
      height: 30px;
    }
  }
  
  .ng-hide-add,
  .ng-hide-remove {
    /* ensure visibility during the transition */
    display: block !important;
    /* yes, important */
  }
  
  .mini-button {
    color: #5cb85c;
  }
  
  .mini-button-minus {
    color: darkorange;
  }
  
  .mini-button:hover {
    color: darkorange;
  }
  
  .mini-text-link > a {
    color: #595959;
  }
  
  .mini-text-link > a:hover {
    color: darkorange;
  }
  
  .mini-text-link {
    background-color: transparent;
  }
  
  @media (max-width: 1129px) {
    .table-responsive {
      border: 1px solid #ddd;
    }
  }
  
  @media (max-width: 1129px) {
    .bordered-table {
      border: none;
    }
  }
  
  @media (max-width: 1129px) {
    .mini-table {
      border: 1px solid #ddd;
    }
  }
  
  #page-wrapper {
    padding-left: 30px;
    padding-right: 30px;
  }
  
  @media (min-width: 768px) {
    #page-wrapper {
      padding-left: 43px;
      padding-top: 25px;
      padding-top: 8px;
      padding-right: 42px;
      padding-bottom: 25px;
      margin-left: 235px;
    }
  }
  
  .panel-body {
    padding: 15px;
  }
  
  .well-lg {
    word-wrap: break-word;
  }
  
  @media (max-width: 590px) {
    .col-xxs {
      display: block;
      width: 50%;
    }
  }
  
  @media (max-width: 435px) {
    .col-xxs {
      display: block;
      width: 80%;
    }
  }
  
  @media (max-width: 340px) {
    .col-xxs {
      display: block;
      width: 100%;
    }
  }
  
  .addProjectPanelHeader {
    padding: 0px;
    background-color: none;
  }
  
  .addProjectPanelHeader .btn-outline {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border: none;
    color: darkorange;
    background-color: #f8f8f8;
    text-align: left;
  }
  
  .addProjectPanelHeader .btn-outline:hover,
  .addProjectPanelHeader .btn-outline:focus {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border: none;
    background-color: #eee;
    color: darkorange;
    outline: none !important;
  }
  
  .addProjectPanelHeader .btn-outline:active {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    background-color: #eee;
    border: none;
    color: darkorgange;
    outline: none !important;
  }
  
  .nav > li > a {
    color: #595959;
    font-family: Arial, Helvetica, sans-serif;
  }
  
  .nav > li > a:hover {
    color: darkorange;
  }
  
  .sidebar {
    margin-top: 56px;
    width: 235px;
  }
  
  .navbar-collapse {
    padding: 0;
  }
  
  .sidebar ul li a.active {
    text-decoration: none;
    background-color: #eee;
  }
  
  /*
  To change this license header, choose License Headers in Project Properties.
  To change this template file, choose Tools | Templates
  and open the template in the editor.
  */
  /* 
      Created on : Jun 12, 2016, 5:33:50 PM
      Author     : sebbe
  */
  .customer-photo-container {
    background-color: black;
    color: blue;
  }
  
  .customer-h1-line {
    padding-bottom: 15px;
    margin: 40px 0 20px;
    border-top: 1px solid #eee;
    margin-top: 15px;
  }
  
  .customer-profile {
    /* max-width: 570px; */
  }
  
  .progress {
    background-color: lemonchiffon;
  }
  
  .progress1 {
    margin-bottom: 0px;
  }
  
  @media (min-width: 500px) {
    #cols {
      -moz-column-count: 2;
      -moz-column-gap: 20px;
      -webkit-column-count: 2;
      -webkit-column-gap: 20px;
      column-count: 2;
      column-gap: 20px;
    }
  }
  
  #cols > ul > li.active > a {
    background-color: black;
  }
  
  #cols > ul > li {
    margin-bottom: 10px;
  }
  
  #cols > ul > li > a {
    color: #595959;
    display: block;
  }
  
  #cols > ul > li > a:hover {
    color: darkorange;
  }
  
  .link-button-mobile-wrapper {
    color: black;
    width: 100%;
    height: 100%;
    background-color: transparent;
    padding: 0px;
    margin: 0px;
  }
  
  .link-button-mobile-wrapper a {
    text-decoration: none;
  }
  
  .link-button-mobile {
    color: black;
    width: 100%;
    height: 100%;
    background-color: transparent;
    border-radius: 0px;
    margin: 0px;
    border: none;
    text-align: left;
    display: inline-block;
    white-space: nowrap;
  }
  
  .link-button-mobile:hover {
    color: black;
    width: 100%;
    height: 100%;
    background-color: transparent;
    border-radius: 0px;
    margin: 0px;
    border: none;
    text-align: left;
    display: inline-block;
    white-space: nowrap;
  }
  
  .link-button-mobile:active {
    color: darkorange;
    width: 100%;
    height: 100%;
    background-color: transparent;
    border-radius: 0px;
    margin: 0px;
    border: none;
    text-align: left;
    display: inline-block;
    white-space: nowrap;
  }
  
  .toggle-switch {
    border: 1px solid black;
    cursor: pointer;
    display: inline-block;
    text-align: left;
    overflow: hidden;
    line-height: 8px;
    min-width: 100px;
    border-radius: 10px;
    padding: 0px;
    margin: 0px;
    margin-top: -1px;
    /* padding-left: 0px; */
    /* margin-left: -1px; */
  }
  
  .toggle-switch .switch-left {
    color: #fff;
    background: forestgreen;
  }
  
  .toggle-switch .switch-left {
    color: #fff;
    background: forestgreen;
  }
  
  .taskLabelEnabled {
    color: darkorange;
    float: left;
  }
  
  .taskLabelDisabled {
    color: lightgray;
    float: left;
  }
  
  /* DatePicker Container */
  .ui-datepicker {
    width: 240px;
    height: auto;
    margin: 5px auto 0;
    font: 9pt Arial, sans-serif;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  }
  
  .ui-datepicker a {
    text-decoration: none;
  }
  
  /* DatePicker Table */
  .ui-datepicker table {
    width: 100%;
    background-color: #bbb;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
  }
  
  .ui-datepicker-header {
    color: #e0e0e0;
    font-weight: bold;
    -webkit-box-shadow: inset 0px 1px 1px 0px #fafafa;
    -moz-box-shadow: inset 0px 1px 1px 0px rgba(250, 250, 250, 0.2);
    box-shadow: inset 0px 1px 1px 0px rgba(250, 250, 250, 0.2);
    text-shadow: 1px -1px 0px #000;
    filter: dropshadow(color=#000, offx=1, offy=-1);
    line-height: 30px;
    border-width: 1px 0 0 0;
    border-style: solid;
    border-color: #111;
    background-color: #373a3c;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
  }
  
  .ui-datepicker-title {
    text-align: center;
  }
  
  .ui-datepicker-prev,
  .ui-datepicker-next {
    display: inline-block;
    width: 30px;
    height: 30px;
    text-align: center;
    cursor: pointer;
    background-image: url("../images/arrow.png");
    background-repeat: no-repeat;
    line-height: 600%;
    overflow: hidden;
  }
  
  .ui-datepicker-prev {
    float: left;
    background-position: center -30px;
  }
  
  .ui-datepicker-next {
    float: right;
    background-position: center 0px;
  }
  
  .ui-datepicker thead {
    background-color: #f7f7f7;
    background-image: -moz-linear-gradient(top, #f7f7f7 0%, #f1f1f1 100%);
    background-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(0%, #f7f7f7),
      color-stop(100%, #f1f1f1)
    );
    background-image: -webkit-linear-gradient(top, #f7f7f7 0%, #f1f1f1 100%);
    background-image: -o-linear-gradient(top, #f7f7f7 0%, #f1f1f1 100%);
    background-image: -ms-linear-gradient(top, #f7f7f7 0%, #f1f1f1 100%);
    background-image: linear-gradient(top, #f7f7f7 0%, #f1f1f1 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f7f7f7', endColorstr='#f1f1f1', GradientType=0);
    border-bottom: 1px solid #bbb;
  }
  
  .ui-datepicker th {
    text-transform: uppercase;
    font-size: 6pt;
    padding: 5px 0;
    color: #666666;
    text-shadow: 1px 0px 0px #fff;
    filter: dropshadow(color=#fff, offx=1, offy=0);
    text-align: center;
  }
  
  .ui-datepicker-week-col {
    padding-left: 4px;
    padding-right: 4px;
  }
  
  .ui-datepicker tbody .ui-datepicker-week-col {
    background-color: #373a3c;
    color: darkorange;
    font-weight: bold;
  }
  
  .ui-datepicker tbody tr:last-child .ui-datepicker-week-col {
    border-bottom-left-radius: 2px;
  }
  
  .ui-datepicker tbody td {
    border-right: 1px solid #bbb;
    border-bottom: 0px;
  }
  
  .ui-datepicker tbody td:last-child {
    border-right: 0px;
  }
  
  .ui-datepicker tbody tr {
    border-bottom: 0px;
  }
  
  .ui-datepicker tbody tr:last-child {
    border-bottom: 0px;
  }
  
  .ui-datepicker td span,
  .ui-datepicker td a {
    display: inline-block;
    font-weight: bold;
    text-align: center;
    width: 30px;
    height: 30px;
    line-height: 30px;
    color: #666666;
    text-shadow: 1px 1px 0px #fff;
    filter: dropshadow(color=#fff, offx=1, offy=1);
  }
  
  .ui-datepicker-calendar .ui-state-default {
    background: #ededed;
    background: -moz-linear-gradient(top, #ededed 0%, #dedede 100%);
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(0%, #ededed),
      color-stop(100%, #dedede)
    );
    background: -webkit-linear-gradient(top, #ededed 0%, #dedede 100%);
    background: -o-linear-gradient(top, #ededed 0%, #dedede 100%);
    background: -ms-linear-gradient(top, #ededed 0%, #dedede 100%);
    background: linear-gradient(top, #ededed 0%, #dedede 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ededed', endColorstr='#dedede', GradientType=0);
    -webkit-box-shadow: inset 1px 1px 0px 0px rgba(250, 250, 250, 0.5);
    -moz-box-shadow: inset 1px 1px 0px 0px rgba(250, 250, 250, 0.5);
    box-shadow: inset 1px 1px 0px 0px rgba(250, 250, 250, 0.5);
  }
  
  .ui-datepicker-calendar .ui-state-hover {
    background-color: black;
    color: darkorange;
    font-weight: bold;
  }
  
  .ui-datepicker-calendar .ui-state-active {
    background: #6eafbf;
    -webkit-box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    color: #e0e0e0;
    text-shadow: 0px 1px 0px #4d7a85;
    filter: dropshadow(color=#4d7a85, offx=0, offy=1);
    border: 1px solid #55838f;
    position: relative;
    margin: -1px;
    width: 32px;
  }
  
  .ui-datepicker-calendar .ui-state-highlight {
    background: whitesmoke;
    color: darkorange;
    text-shadow: 0px 1px 0px #4d7a85;
    filter: dropshadow(color=#4d7a85, offx=0, offy=1);
    position: relative;
    text-shadow: none;
  }
  
  .ui-datepicker-unselectable .ui-state-default {
    background: #f4f4f4;
    color: #b4b3b3;
  }
  
  .ui-datepicker-calendar td:first-child .ui-state-active {
    width: 29px;
    margin-left: 0;
  }
  
  .ui-datepicker-calendar td:last-child .ui-state-active {
    width: 31px;
    margin-right: 0;
  }
  
  .ui-datepicker-calendar tr:last-child .ui-state-active {
    height: 29px;
    margin-bottom: 0;
  }
  
  .ui-datepicker-week-col thead {
    background-color: #f7f7f7;
    background-image: -moz-linear-gradient(top, #f7f7f7 0%, #f1f1f1 100%);
    background-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(0%, #f7f7f7),
      color-stop(100%, #f1f1f1)
    );
    background-image: -webkit-linear-gradient(top, #f7f7f7 0%, #f1f1f1 100%);
    background-image: -o-linear-gradient(top, #f7f7f7 0%, #f1f1f1 100%);
    background-image: -ms-linear-gradient(top, #f7f7f7 0%, #f1f1f1 100%);
    background-image: linear-gradient(top, #f7f7f7 0%, #f1f1f1 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f7f7f7', endColorstr='#f1f1f1', GradientType=0);
    border-bottom: 1px solid #bbb;
  }
  
  .ui-datepicker-week-col th {
    text-transform: uppercase;
    font-size: 6pt;
    padding: 5px 0;
    color: #666666;
    text-shadow: 1px 0px 0px #fff;
    filter: dropshadow(color=#fff, offx=1, offy=0);
    text-align: center;
  }
  
  /*
  To change this license header, choose License Headers in Project Properties.
  To change this template file, choose Tools | Templates
  and open the template in the editor.
  */
  /* 
      Created on : 2016-apr-07, 13:46:19
      Author     : Sebbe
  */
  .sentreport-row {
    height: 88vh;
    padding-top: 70px;
  }
  
  .sent-container {
    width: 100%;
    height: 100vh;
    background-color: #005490;
    margin: 0px;
  }
  
  .sent-container h1 {
    margin-top: 0px;
  }
  
  .mobile-table-container3 {
    min-height: 65vh;
    background-image: linear-gradient(180deg, #005490, #99b9e8);
    margin-top: -3px;
    margin-bottom: -3px;
    border: 1px solid #black;
    z-index: -1;
  }
  
  .row-sent-page-upper {
    padding-top: 25vh;
    color: white;
    margin-bottom: 15.5vh;
  }
  
  .row-sent-page-down {
    margin-bottom: 0px;
  }
  
  .sent-ok-container {
    border-radius: 90px;
    border: 3px solid green;
    width: 150px;
    height: 150px;
    margin-top: 30px;
    padding: 20px;
    background-color: #a9db80;
    background-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#a9db80),
      to(#96c56f)
    );
    background-image: -webkit-linear-gradient(top, #a9db80, #96c56f);
    background-image: -moz-linear-gradient(top, #a9db80, #96c56f);
    background-image: -ms-linear-gradient(top, #a9db80, #96c56f);
    background-image: -o-linear-gradient(top, #a9db80, #96c56f);
    background-image: linear-gradient(to bottom, #a9db80, #96c56f);
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=#a9db80, endColorstr=#96c56f);
  }
  
  .glyphicon-mobile-sent {
    font-size: 100px;
    color: forestgreen;
  }
  
  .glyphicon-mobile-sent-arrow {
    font-size: 50px;
    color: forestgreen;
    display: inline;
    margin: 0px;
    padding-top: 0px;
    padding-bottom: 11px;
    padding-left: 15px;
    vertical-align: middle;
  }
  
  .sent-button-mobile {
    width: 100%;
    border-top: 2px solid black;
    color: black;
    padding-top: 20px;
    padding-bottom: 20px;
    -webkit-box-shadow: 0px 3px 5px 0px rgba(50, 50, 50, 0.75);
    -moz-box-shadow: 0px 3px 5px 0px rgba(50, 50, 50, 0.75);
    box-shadow: 0px 3px 5px 0px rgba(50, 50, 50, 0.75);
    background-color: #e6e6e6;
    background-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#e6e6e6),
      to(#cccccc)
    );
    background-image: -webkit-linear-gradient(top, #e6e6e6, #cccccc);
    background-image: -moz-linear-gradient(top, #e6e6e6, #cccccc);
    background-image: -ms-linear-gradient(top, #e6e6e6, #cccccc);
    background-image: -o-linear-gradient(top, #e6e6e6, #cccccc);
    background-image: linear-gradient(to bottom, #e6e6e6, #cccccc);
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=#E6E6E6, endColorstr=#CCCCCC);
  }
  
  .sent-button-mobile h4 {
    vertical-align: middle;
  }
  
  .sent-button-mobile:hover {
    border-top: 2px solid black;
    background-color: #646464;
    background-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#646464),
      to(#282828)
    );
    background-image: -webkit-linear-gradient(top, #646464, #282828);
    background-image: -moz-linear-gradient(top, #646464, #282828);
    background-image: -ms-linear-gradient(top, #646464, #282828);
    background-image: -o-linear-gradient(top, #646464, #282828);
    background-image: linear-gradient(to bottom, #646464, #282828);
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=#646464, endColorstr=#282828);
  }
  
  .sent-button-mobile:active {
    border-top: 2px solid black;
    color: black;
    background-color: #646464;
    background-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#646464),
      to(#282828)
    );
    background-image: -webkit-linear-gradient(top, #646464, #282828);
    background-image: -moz-linear-gradient(top, #646464, #282828);
    background-image: -ms-linear-gradient(top, #646464, #282828);
    background-image: -o-linear-gradient(top, #646464, #282828);
    background-image: linear-gradient(to bottom, #646464, #282828);
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=#646464, endColorstr=#282828);
    -webkit-box-shadow: inset 0 0 22px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: inset 0 0 22px rgba(0, 0, 0, 0.75);
    box-shadow: inset 0 0 22px rgba(0, 0, 0, 0.75);
  }
  
  .sent-button-mobile:focus {
    border-top: 2px solid black;
    color: white;
    background-color: #646464;
    background-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#646464),
      to(#282828)
    );
    background-image: -webkit-linear-gradient(top, #646464, #282828);
    background-image: -moz-linear-gradient(top, #646464, #282828);
    background-image: -ms-linear-gradient(top, #646464, #282828);
    background-image: -o-linear-gradient(top, #646464, #282828);
    background-image: linear-gradient(to bottom, #646464, #282828);
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=#646464, endColorstr=#282828);
  }
  
  #user-green-lock {
    color: limegreen;
  }
  
  #dropdown-menu-color {
    color: #595959;
  }
  
  #dropdown-menu-color:hover {
    color: darkorange;
  }
  
  #dropdown-menu-color:active {
    color: darkorange;
  }
  
  .dropdown-menu-color {
    color: darkorange !important;
  }
  
  .dropdown-menu-color-add {
    color: #449d44 !important;
  }
  
  .panel-body-heigher-height {
    padding-left: 0px;
    padding-right: 0px;
  }
  
  .tasks-columns-inner {
    overflow: hidden;
    /* fix for Firefox */
    break-inside: avoid-column;
    -webkit-column-break-inside: avoid;
  }
  
  .tasks-columns {
    -webkit-column-count: 1;
    /* Chrome, Safari, Opera */
    -moz-column-count: 1;
    /* Firefox */
    column-count: 1;
    -webkit-column-gap: 40px;
    /* Chrome, Safari, Opera */
    -moz-column-gap: 40px;
    /* Firefox */
    column-gap: 40px;
  }
  
  @media (min-width: 1050px) {
    .tasks-columns {
      -webkit-column-count: 2;
      /* Chrome, Safari, Opera */
      -moz-column-count: 2;
      /* Firefox */
      column-count: 2;
      -webkit-column-gap: 40px;
      /* Chrome, Safari, Opera */
      -moz-column-gap: 40px;
      /* Firefox */
      column-gap: 40px;
    }
  }
  
  .internalCustomer {
    border-bottom: 1px solid #d6e9c6;
    border-top: 1px solid #d6e9c6;
  }
  
  .emptyEvent {
    font-size: 8em;
    height: 190px;
    margin: 0px;
    text-align: center;
    color: #e7e7e7;
    border: 1px solid #e7e7e7;
    border-radius: 5px;
    background-color: #f8f8f8;
    padding: 0px;
    padding-top: 22px;
  }
  
  /* set reference point */
  .tab-animation > .tab-content {
    position: relative;
  }
  
  /* set animate effect */
  .tab-animation > .tab-content > .tab-pane {
    transition: 0.2s linear opacity;
  }
  
  /* overwrite display: none and remove from document flow */
  .tab-animation > .tab-content > .tab-pane.active-remove {
    position: absolute;
    top: 0;
    width: 100%;
    display: block;
  }
  
  /* opacity=0 when removing "active" class */
  .tab-animation > .tab-content > .tab-pane.active-remove-active {
    opacity: 0;
  }
  
  /* opacity=0 when adding "active" class */
  .tab-animation > .tab-content > .tab-pane.active-add {
    opacity: 0;
  }
  
  .table-responsive {
    border-radius: 0px;
    border: 1px solid #ddd;
  }
  
  .tabNotActive {
    margin-right: 2px;
    line-height: 1.42857143;
    border: 1px solid transparent;
    border-radius: 4px 4px 0 0;
  }
  
  .disabledtrue {
    color: #999;
  }
  
  .modal-fullscreen {
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin: 0 !important;
  }
  
  .timereport-date {
    float: right;
    font-weight: 700;
    color: darkorange;
  }
  
  .send-text {
    font-size: 12px;
    font-stretch: wider;
    letter-spacing: 1px;
    margin: 2px 0 -3px;
  }
  
  .checkbox-container {
    float: right;
    width: 74px;
  }
  
  .checkbox-container-customer {
    float: right;
    width: 83px;
  }
  
  .bordered-table {
    border: none;
  }
  
  .mini-table {
    border: 1px solid #ddd;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  .internalCustomer {
    border-bottom: 4px solid #d6e9c6;
    border-top: 4px solid #d6e9c6;
  }
  
  @media (min-width: 768px) {
    #page-wrapper {
      /* padding: 8px 42px 25px 43px; */
      margin-left: 0px;
    }
  
    .navbar-header {
      float: none;
    }
  }
  
  #btn-add {
    float: none;
  }
  
  .modal-title {
    color: darkorange;
  }
  
  .dropdown-menu {
    font-size: 16px;
  }
  
  .footer-buttons {
    width: 100%;
    margin-top: 7px;
  }
  
  .footer-hours-container {
    padding: 5px;
    text-align: center;
    color: darkorange;
  }
  
  .footer-hours {
    margin: 0px;
    font-family: Lucida Sans Typewriter, Lucida Console, monaco,
      Bitstream Vera Sans Mono, monospace;
    font-size: 14px;
    font-style: normal;
    font-variant: normal;
    font-weight: 400;
    line-height: 20px;
  }
  
  .headingHours {
    color: #555;
    margin: 0px;
    font-size: 14px;
    font-style: normal;
    font-variant: normal;
    font-weight: 400;
    line-height: 20px;
  }
  
  .todayTotalHours {
    color: #5cb85c;
    margin: 0px;
    font-family: Lucida Sans Typewriter, Lucida Console, monaco,
      Bitstream Vera Sans Mono, monospace;
    font-size: 14px;
    font-style: normal;
    font-variant: normal;
    font-weight: 400;
    line-height: 20px;
  }
  
  .userTodayTotalHours {
    display: inline-block;
    margin: 0px;
    font-style: normal;
    font-variant: normal;
    text-shadow: #000 0px 0px 1px, #000 0px 0px 1px, #000 0px 0px 1px,
      #000 0px 0px 1px, #000 0px 0px 1px, #000 0px 0px 1px, #000 0px 0px 1px,
      #000 0px 0px 1px, #000 0px 0px 1px, #000 0px 0px 1px, #000 0px 0px 1px,
      #000 0px 0px 1px, #000 0px 0px 1px, #000 0px 0px 1px;
    -webkit-font-smoothing: antialiased;
  }
  
  .mobile-panel-body {
    margin-bottom: 38px;
    padding-bottom: 0px;
  }
  
  .mobile-footer {
    position: fixed;
    bottom: 80px;
    left: 0;
    right: 0;
    height: 63px;
    background: rgba(255, 255, 255, 0.8);
    border-top: 1px solid lightgray;
  }
  
  .mobile-footer-edit-report .btn-block + .btn-block {
    margin-top: -1px;
  }

  @media (min-width: 900px) {
    .mobile-footer {
      bottom: 0;
    }
  }
  
  .editReportPanelBody {
    margin-top: -31px;
    margin-bottom: 94px;
  }
  
  .go-to-edit-btn {
    margin-left: 1%;
    margin-right: 1%;
    width: 98%;
    margin-top: 0px;
    border-top: none;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }
  
  .edit-report-btn {
    margin-left: 1%;
    margin-right: 1%;
    width: 47%;
    margin-top: 0px;
    border-top: none;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }
  
  .projectTableCell {
    height: 63px;
    vertical-align: middle;
  }
  
  .projectDoneTodayButton [data-icon] {
    padding-left: 10px;
    white-space: normal;
  }
  
  .projectDoneTodayButton [data-icon]:before {
    font-family: "Glyphicons Halflings";
    content: "\e013";
    color: lightgray;
    float: right;
    text-shadow: #000 0px 0px 1px, #000 0px 0px 1px, #000 0px 0px 1px,
      #000 0px 0px 1px, #000 0px 0px 1px, #000 0px 0px 1px, #000 0px 0px 1px,
      #000 0px 0px 1px, #000 0px 0px 1px, #000 0px 0px 1px, #000 0px 0px 1px,
      #000 0px 0px 1px, #000 0px 0px 1px, #000 0px 0px 1px;
    -webkit-font-smoothing: antialiased;
  }
  
  .projectDoneTodayButton [data-icon-done] {
    padding-left: 10px;
    white-space: normal;
  }
  
  .projectDoneTodayButton [data-icon-done]:before {
    font-family: "Glyphicons Halflings";
    content: "\e013";
    color: lime;
    float: right;
    text-shadow: #000 0px 0px 1px, #000 0px 0px 1px, #000 0px 0px 1px,
      #000 0px 0px 1px, #000 0px 0px 1px, #000 0px 0px 1px, #000 0px 0px 1px,
      #000 0px 0px 1px, #000 0px 0px 1px, #000 0px 0px 1px, #000 0px 0px 1px,
      #000 0px 0px 1px, #000 0px 0px 1px, #000 0px 0px 1px;
    -webkit-font-smoothing: antialiased;
  }
  
  .link-button-mobile {
    vertical-align: middle;
    white-space: nowrap;
  }
  
  .link-button-mobile [data-icon] {
    padding-left: 10px;
    white-space: normal;
  }
  
  .link-button-mobile [data-icon]:before {
    font-family: "Glyphicons Halflings";
    content: "\e013";
    float: right;
    color: lime;
    text-shadow: #000 0px 0px 1px, #000 0px 0px 1px, #000 0px 0px 1px,
      #000 0px 0px 1px, #000 0px 0px 1px, #000 0px 0px 1px, #000 0px 0px 1px,
      #000 0px 0px 1px, #000 0px 0px 1px, #000 0px 0px 1px, #000 0px 0px 1px,
      #000 0px 0px 1px, #000 0px 0px 1px, #000 0px 0px 1px;
    -webkit-font-smoothing: antialiased;
  }
  
  .button-container-project-adress {
    padding: 20px;
    margin-left: 15px;
    margin-right: 15px;
  }
  
  .reportTimeContainer {
    margin-bottom: 0px;
  }
  
  .reportTimePLeft {
    color: darkorange;
  }
  
  .reportTimePRight {
    color: darkorange;
  }
  
  .arrowContainer {
    padding: 0px;
    text-align: center;
  }
  
  .mini-modal {
    /* border: 5px solid #ff8c00; */
    /* border-radius: 10px; */
    width: 98%;
    margin-left: 1%;
    margin-right: 1%;
    margin-top: 50%;
  }
  
  .mini-modal-row {
    margin-left: -15px;
    margin-right: -15px;
  }
  
  .addTimeMinutes {
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -webkit-transition: border-color ease-in-out 0.15s,
      -webkit-box-shadow ease-in-out 0.15s;
    -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    width: 51px;
    border-radius: 5px;
    text-align: center;
    display: inline;
    margin-top: 7px;
    margin-bottom: 7px;
  }
  
  .addTimeHours {
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -webkit-transition: border-color ease-in-out 0.15s,
      -webkit-box-shadow ease-in-out 0.15s;
    -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    width: 51px;
    border-radius: 5px;
    text-align: center;
    display: inline;
    margin-top: 7px;
    margin-bottom: 7px;
  }
  
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  input[type="number"] {
    -moz-appearance: textfield;
  }
  
  .front-page .table > tbody > tr > td,
  .front-page .table > tbody > tr > th,
  .front-page .table > tfoot > tr > td,
  .front-page .table > tfoot > tr > th,
  .front-page .table > thead > tr > td,
  .front-page .table > thead > tr > th {
    padding: 15px;
    line-height: 1.42857143;
    vertical-align: middle;
    border-top: 1px solid #ddd;
  }
  
  .edit-report .table > tbody > tr > td,
  .edit-report .table > tfoot > tr > td,
  .edit-report .table > tfoot > tr > th,
  .edit-report .table > thead > tr > td {
    padding: 2px;
    padding-left: 10px;
    padding-right: 10px;
    line-height: 1.42857143;
    vertical-align: middle;
    border-top: 1px solid #ddd;
  }
  
  .edit-report .table > tbody > tr > th,
  .edit-report .table > thead > tr > th {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  
  .mini-table {
    margin-top: 15px;
  }
  
  .navbar-top-links li:last-child {
    margin-right: 5px;
    /* margin: 15px; */
  }
  
  .navbar-top-links li {
    /* margin-right: 10px;
      /* margin: 15px; */
  }
  
  .panel-heading-higher-hight {
    height: 40px;
  }
  
  .front-row {
    margin: 0px;
  }
  
  
  .mobile-front-button {
    height: 120px;
    margin-bottom: 15px;
  }
  
  #page-wrapper {
    padding: 0 15px;
    min-height: 100%;
    background-color: #fff;
  }
  
  body {
    padding-bottom: 0px;
  }
  
  .panel-footer {
    padding: 10px 15px;
    background-color: #f5f5f5;
    border-top: 1px solid #ddd;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    height: 20px;
    padding-top: 0px;
  }
  
  .panel {
    margin-top: 20px;
    margin-bottom: 40px;
    background-color: #fff;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  }
  
  .panel-higher-height {
    min-height: 450px;
    margin-top: 20px;
    margin-bottom: 40px;
    background-color: #fff;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  }
  
  textarea {
    resize: none;
  }
  
  .user-menu {
    float: right;
  }
  
  .navbar-top-links .dropdown-user {
    /* margin-right: 50px; */
    margin-left: -133px;
  }
  
  .projectNewCustomerObject {
    -moz-transition: 1s;
    -ms-transition: 1s;
    -o-transition: 1s;
    -webkit-transition: 1s;
    transition: 1s;
  }
  
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: overlay;
  }
  
  .btn-project-existing-object {
    width: 281px;
  }
  
  .btn-project-new-object {
    width: 100%;
    text-align: left;
    background-color: white;
  }
  
  .btn-project-new-object:hover {
    color: darkorange;
    background-color: #eee;
  }
  
  .panel-project-new-object {
    border-color: darkorange;
  }
  
  @media (max-width: 768px) {
    .centerContainer {
      text-align: center;
    }
  
    .btn-project-existing-object {
      width: 100%;
    }
  }
  
  .isItChecked {
    height: 34px;
  }
  
  .animate-show1 {
    opacity: 1;
  }
  
  .animate-show1.ng-hide-add,
  .animate-show1.ng-hide-remove {
    transition: all linear 0.5s;
  }
  
  .animate-show1.ng-hide {
    opacity: 0;
    padding: 0px;
    height: 0px;
  }
  
  .animate-show2 {
    opacity: 1;
  }
  
  .animate-show2.ng-hide-add,
  .animate-show2.ng-hide-remove {
    transition: all linear 0.5s;
    opacity: 0;
  }
  
  .animate-show2.ng-hide {
    opacity: 0;
    height: 0px;
  }
  
  .testP {
    float: right;
  }
  
  .totalNumber {
    margin-right: 9px;
  }
  
  .sidebar {
    margin-top: 0px;
    width: 100%;
  }
  
  @media (min-width: 768px) {
    .sidebar {
      margin-top: 56px;
      width: 235px;
    }
  }
  
  .dropdown-alerts {
    margin-left: 0px;
  }
  
  .navbar-top-links {
    margin-left: 0px;
  }
  
  .text-muted {
    color: #777;
    margin-bottom: 0px;
    margin-top: -5px;
  }
  
  .special-input {
    margin-top: 16px;
  }
  
  .form-horizontal .form-group {
    margin-left: 0px;
    margin-right: 0px;
  }
  
  .miniHoursContainer {
    margin: 15px;
    float: left;
    display: table;
    width: 100%;
  }
  
  .dateWidth {
    max-width: 200px;
  }
  
  .columns {
    width: 100%;
    margin-top: 25px;
    -webkit-column-gap: 0px;
    /* Chrome, Safari, Opera */
    -moz-column-gap: 0px;
    /* Firefox */
    column-gap: 0px;
    -webkit-column-count: 1;
    /* Chrome, Safari, Opera */
    -moz-column-count: 1;
    /* Firefox */
    column-count: 1;
  }
  
  .columns ul {
    list-style-type: none;
  }
  
  .switchery-mini {
    height: 15px;
    width: 35px;
  }
  
  .switchery-circle-mini {
    height: 15px;
    width: 15px;
  }
  
  .other-icons {
    margin-right: 4px;
  }
  
  .switchery2 {
    background-color: #fff;
    border: 1px solid #dfdfdf;
    border-radius: 20px;
    cursor: pointer;
    display: inline-block;
    height: 15px;
    position: relative;
    vertical-align: middle;
    width: 35px;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    box-sizing: content-box;
    background-clip: content-box;
  }
  
  .switchery2 > small {
    background: #fff;
    border-radius: 100%;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
    height: 15px;
    position: absolute;
    top: 0;
    width: 15px;
  }
  
  @media (max-width: 767px) {
    .collapsing {
      -webkit-transition: height 0.01s ease;
      -o-transition: height 0.01s ease;
      transition: height 0.01s ease;
    }
  }
  
  .hoursContainer {
    margin-top: 0px;
    margin: 0 auto;
    max-width: 150px;
  }
  
  @media (max-width: 767px) {
    .col-xxs-project {
      display: block;
      width: 50%;
    }
  }
  
  @media (max-width: 750px) {
    .col-xxs-project {
      display: block;
    }
  }
  
  @media (max-width: 667px) {
    .col-xxs-project {
      display: block;
      width: 100%;
    }
  
    .hourWidth {
      width: 100%;
      max-width: 200px;
    }
  
    .hoursContainer {
      margin: 0 auto;
      margin-top: 0px;
      margin-bottom: 15px;
    }
  }
  
  @media (max-width: 450px) {
    .col-xxs-project {
      display: block;
      width: 100%;
      padding-right: 15px;
    }
  
    .hoursContainer {
      margin: 0 auto;
      margin-top: 0px;
      margin-bottom: 15px;
    }
  }
  
  .hourWidth {
    margin: 0 auto;
    margin-top: 14px;
    margin-bottom: 14px;
    max-width: 200px;
  }
  
  .ng-hide-add,
  .ng-hide-remove {
    /* ensure visibility during the transition */
    display: block !important;
    /* yes, important */
  }
  
  .cssFade {
    transition: 0.5s linear all;
    opacity: 1;
  }
  
  .cssFade.ng-hide {
    opacity: 0;
  }
  
  .well-xxs-project {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 15px;
    padding-left: 15px;
  }
  
  @media (max-width: 449px) {
    .well-xxs-project {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
  
  .input-date-ui {
    height: 35px;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    position: relative;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -webkit-transition: border-color ease-in-out 0.15s,
      -webkit-box-shadow ease-in-out 0.15s;
    -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    /**
       display: table-cell;
       position: relative;
       z-index: 2;
       //float: left;
       width: 100%;
       margin-bottom: 0;
  
  
  
       font-size: 14px;
       line-height: 1.42857143;
       color: #555;
  
  
       background-color: #fff;
       background-image: none;
       border: 1px solid #ccc;
       border-radius: 4px;
       -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
       box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
       -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
      -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
      transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
        */
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  
  .calendar-right-arrow {
    color: #f0ad4e;
    display: inline-block;
    float: right;
    margin-top: 6px;
    margin-right: 7px;
  }
  
  .calendar-left-arrow {
    color: #f0ad4e;
    display: inline-block;
    float: left;
    margin-top: 6px;
    margin-left: 7px;
  }
  
  .calendar-title {
    display: inline-block;
    color: #f0ad4e;
    margin-top: 5px;
  }
  
  .panel-calendar {
    margin-bottom: 95px;
  }
  
  .calendar-row {
    min-height: 100vh;
    margin-left: -12px;
    margin-right: -12px;
  }
  
  .cal-month-day .cal-events-num {
    display: none;
  }
  
  .event {
    display: block;
    background-color: #c3c3c3;
    width: 12px;
    height: 12px;
    margin-right: 2px;
    margin-bottom: 2px;
    -webkit-box-shadow: inset 0px 0px 5px 0px rgba(0, 0, 0, 0.4);
    box-shadow: inset 0px 0px 5px 0px rgba(0, 0, 0, 0.4);
    border-radius: 8px;
    border: 1px solid #ffffff;
    /**
        //background-color: lime;
        margin-right: 0px;
        margin-bottom: 16px;
        margin-left: 29px;
        width: 0;
        height: 0;
  
            &:before {
                font-family: "Glyphicons Halflings";
                font-size: 12px;
                content: "\e013";
                color: lime;
                float: right;
                //color: lime;
                text-shadow: #000 0px 0px 1px,
                    #000 0px 0px 1px,
                    #000 0px 0px 1px,
                    #000 0px 0px 1px,
                    #000 0px 0px 1px,
                    #000 0px 0px 1px,
                    #000 0px 0px 1px,
                    #000 0px 0px 1px,
                    #000 0px 0px 1px,
                    #000 0px 0px 1px,
                    #000 0px 0px 1px,
                    #000 0px 0px 1px,
                    #000 0px 0px 1px,
                    #000 0px 0px 1px;
                -webkit-font-smoothing: antialiased;
            }
        */
  }
  
  .cal-month-box .cal-day-today span[data-cal-date] {
    font-size: 1.2em;
  }
  
  .cal-day-today {
    background-color: #f0ad4e;
  }
  
  .event-checked {
    display: block;
    background-color: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: none;
    border: none;
    margin-bottom: 5px;
    margin-left: -2px;
  }
  
  .event-checked:before {
    font-family: "Glyphicons Halflings";
    font-size: 12px;
    content: "\e013";
    color: lime;
    float: right;
    text-shadow: #000 0px 0px 1px, #000 0px 0px 1px, #000 0px 0px 1px,
      #000 0px 0px 1px, #000 0px 0px 1px, #000 0px 0px 1px, #000 0px 0px 1px,
      #000 0px 0px 1px, #000 0px 0px 1px, #000 0px 0px 1px, #000 0px 0px 1px,
      #000 0px 0px 1px, #000 0px 0px 1px, #000 0px 0px 1px;
    -webkit-font-smoothing: antialiased;
  }
  .event-checked-need-attest:before {
      color: orange;
  }
  
  .event-checked-attested:before {
      color: lime;
  }
  
  .event-checked-rejected:before {
      color: red;
  }
  
  .event-unchecked {
    display: block;
    background-color: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: none;
    border: none;
    margin-bottom: 5px;
    margin-left: -2px;
  }
  
  .cal-event-list .event.pull-left {
    margin-top: 3px;
    display: none;
  }
  
  .cal-slide-content {
    padding-top: 31px;
  }
  
  .cal-slide-content a.event-item {
    color: #ffffff;
    font-weight: normal;
    display: none;
  }
  
  .cal-slide-content-title {
    color: white;
  }
  
  .cal-slide-content-title:hover {
    color: white;
  }
  
  .loaderIcon {
    background: url(../images/mygizmo_loader.gif);
    height: 20px;
    width: 20px;
    background-size: cover;
  }
  
  .docx-wrapper {
      background: none !important;
  }

  .dropArea {
    background-color: white;
    padding: 0;
    transition: background-color 200ms;
    box-sizing: content-box;
}

.dropArea > div {
    border: 2px dashed #7eb5e4;
    transition: background-color 200ms;
    padding: 16px;
}

.is-dragover {
    background-color: #d7e8f7;
}
