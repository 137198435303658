html {
  font-size: 13px !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f8f8f8;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.selectedCard {
  background: linear-gradient(to top, #FF9900, #ffcc80) !important;
  background: -webkit-linear-gradient(to top, #FF9900, #ffcc80) !important;  /* Chrome 10-25, Safari 5.1-6 */
}

.bigSelectedCard {
  background: linear-gradient(to top right, #FF9900, #ffcc80) !important;
  background: -webkit-linear-gradient(to top right, #FF9900, #ffcc80) !important;  /* Chrome 10-25, Safari 5.1-6 */
}

.modal-80w {
  min-width:95%;
}